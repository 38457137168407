import Vue from 'vue'
import Vuex from 'vuex'
import * as purchaseOrders from '@/services/purchase-orders'
import utilities from '@/services/utilities'
import moment from 'moment'
import Swal from 'sweetalert2'
import _ from 'lodash'
import router from '@/router'

Vue.use(Vuex)

const dateFormat = 'YYYY-MM-DD'
const summaryModel = {
	low: {
		count: 0,
		days: 0,
	},
	mid: {
		count: 0,
		days: 0,
	},
	high: {
		count: 0,
		days: 0,
	},
	undefined: 0,
}

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		purchaseOrdersList: [],
		actualPurchaseOrder: {},
		filters: {
			status: 'C',
			payment_method: '',
			defaultDate: [moment().startOf('month').format(dateFormat), moment().endOf('month').format(dateFormat)],
			defaultDay: [moment().startOf('day').format(dateFormat), moment().endOf('day').format(dateFormat)],
			ticket: '',
			supplier_id: '',
			destination: '',
		},
		summaryData: _.cloneDeep(summaryModel),
		summaryDataFiltered: _.cloneDeep(summaryModel),
		summaryDataByArea: [],
		fileURL: '',
		modalFileVisible: false,
		totalPurchaseOrders: {
			totalCash: 0,
			totalDigital: 0,
			totalMercadoPago: 0,
		},
		isCashODC: false,
		fromRLS: false,
		branch_office_id: 1,
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			purchaseOrders
				.get(payload)
				.then((response) => {
					commit('SET_STATE', {
						purchaseOrdersList: _.cloneDeep(response.data),
					})

					if (payload.status == 2) {
						commit('SET_STATE', {
							totalPurchaseOrders: _.cloneDeep(response.totales),
						})
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Ordenes de Compra',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						purchaseOrdersList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async CREATE({ commit }, { payload, formData }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información',
			})
			return new Promise((resolve, reject) => {
				purchaseOrders
					.create(payload)
					.then(async (response) => {
						if (formData) {
							formData.append('purchase_order_id', response.data.purchase_order_id)
							await purchaseOrders.uploadFile('file', formData)
						}
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(response, 'message', 'ODC generada con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						})
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPLOAD_FILES({ commit, dispatch }, { field, formDataPayment, onlyReloadRecord, formDataPDF, formDataXML }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información',
			})

			if (formDataPDF && formDataXML) {
				if (formDataPDF) {
					await purchaseOrders.uploadFile('globalPDF', formDataPDF)
				}
				if (formDataXML) {
					await purchaseOrders.uploadFile('globalXML', formDataXML)
				}
				Swal.fire({
					title: 'ODC actualizada',
					text: 'ODC actualizada con éxito',
					icon: 'success',
					confirmButtonText: 'Ok',
				}).then(() => {
					dispatch('GET_ONE', formDataPDF.get('purchase_order_id'))
				})
			} else {
				purchaseOrders
					.uploadFile(field, formDataPayment)
					.then(async (response) => {
						Swal.fire({
							title: 'ODC actualizada',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							if (onlyReloadRecord) {
								dispatch('GET_ONE', formDataPayment.get('purchase_order_id'))
							} else {
								router.push(`/purchase-orders`)
							}
						})
					})
					.catch((error) => {
						console.log('error-->', error)
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			}
		},
		async REQUEST_VALIDATION({ commit, dispatch }, { formDataPDF, formDataXML, onlyReloadRecord }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información',
			})

			if (formDataPDF) {
				await purchaseOrders.uploadFile('pdf', formDataPDF)
			}
			if (formDataXML) {
				await purchaseOrders.uploadFile('xml', formDataXML)
			}

			Swal.fire({
				title: 'ODC actualizada',
				text: 'La ODC ha pasado a facturadas',
				icon: 'success',
				confirmButtonText: 'Ok',
			}).then(() => {
				if (onlyReloadRecord) {
					dispatch('GET_ONE', formDataPDF.get('purchase_order_id'))
				} else {
					router.push(`/purchase-orders`)
				}
			})
		},

		UPDATE({ commit, dispatch }, { payload, formData }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando información',
			})
			purchaseOrders
				.update(payload)
				.then(async (response) => {
					if (formData) {
						//
						if (formData) {
							formData.append('purchase_order_id', payload.id)
							await purchaseOrders.uploadFile('file', formData)
						}
					}
					Swal.fire({
						title: 'Ordenes de compra',
						text: utilities.objectValidate(response, 'message', 'ODC generada con éxito.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						// dispatch('GET_ONE', payload.id)
						router.push(`/purchase-orders`)
					})
				})
				.catch((error) => {
					console.log('error-->', error)
					Swal.fire({
						title: 'Ordenes de compra',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_FILE_URL({ commit }, { id, action }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
				fileURL: '',
			})
			purchaseOrders
				.getFileUrl(id)
				.then((response) => {
					if (action == 'download') {
						window.open(response.data.url)
					} else {
						commit('SET_STATE', {
							fileURL: _.cloneDeep(response.data.url),
							modalFileVisible: true,
						})
					}
				})
				.catch((error) => {
					Swal.fire({
						title: 'Ordenes de compra',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener el registro.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ONE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
			})
			purchaseOrders
				.getOne(id)
				.then((response) => {
					commit('SET_STATE', {
						actualPurchaseOrder: _.cloneDeep(response.data),
						isCashODC: response.data.is_cash_odc,
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Ordenes de compra',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener el registro.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CONFIRM({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando datos',
			})
			return new Promise((resolve, reject) => {
				purchaseOrders
					.confirm(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		REJECT({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando datos',
			})
			return new Promise((resolve, reject) => {
				purchaseOrders
					.reject(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		DELIVERY({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando datos',
			})
			return new Promise((resolve, reject) => {
				purchaseOrders
					.delivery(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro de entrega.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						}).then(() => {
							reject(error)
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		DISABLE({ commit, dispatch, state }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			purchaseOrders
				.disable(id)
				.then((response) => {
					Swal.fire({
						title: 'Ordenes de compra',
						text: utilities.objectValidate(response, 'message', 'Registro eliminado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET', state.filters)
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Ordenes de compra',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al eliminar el registro.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		RESET_FILTERS({ commit, dispatch }) {
			commit('SET_STATE', {
				filters: {
					status: '0',
				},
			})
		},
		RESET_ONE_FILTER({ commit, dispatch, state }, key) {
			let filters = {
				...state.filters,
			}
			filters[key] = '0'

			commit('SET_STATE', {
				filters,
			})
		},
		// Clear Purchase Orders List
		CLEAR_POL({ commit }) {
			commit('SET_STATE', {
				purchaseOrdersList: [],
			})
		},
		UPDATE_PAYMENT_METHOD({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			return new Promise((resolve, reject) => {
				purchaseOrders
					.updatePaymentMethod(payload)
					.then((response) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							resolve()
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						}).then(() => {
							reject()
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		RESTORE_ORDER({ commit, dispatch }, orderId) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			return new Promise((resolve, reject) => {
				purchaseOrders
					.restoreOrder(orderId)
					.then((response) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							resolve()
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						}).then(() => {
							reject()
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		GET_SUMMARY({ commit, dispatch }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			purchaseOrders
				.getPurchaseOrderSummary()
				.then((response) => {
					commit('SET_STATE', {
						summaryData: response.data,
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Ordenes de compra',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener información.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_FILTERED_SUMMARY({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			purchaseOrders
				.getPurchaseOrderSummaryFiltered(payload)
				.then((response) => {
					commit('SET_STATE', {
						summaryDataFiltered: response.data.summaryDataFiltered,
						summaryDataByArea: response.data.summaryDataByArea,
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Ordenes de compra',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener información.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SEARCH({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			purchaseOrders
				.search(payload)
				.then((response) => {
					commit('SET_STATE', {
						purchaseOrdersList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Ordenes de compra',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener información.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SET_INVOICE({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				purchaseOrders
					.setInvoice(id)
					.then((response) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							resolve()
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						}).then(() => {
							reject()
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async SET_LOCAL({ commit, dispatch, rootState, state }, id) {
			if (!rootState.suppliers.suppliersList.length) {
				await dispatch('suppliers/GET_NO_REDIRECT', null, { root: true })
			}
			let theRecord = state.purchaseOrdersList.find((e) => e.id == id)
			commit('SET_STATE', {
				actualPurchaseOrder: _.cloneDeep(theRecord),
				isCashODC: theRecord.is_cash_odc,
			})
			setTimeout(() => {
				router.push(`/purchase-orders/${id}`)
			}, 100)
		},
		async UPDATE_STATUS({ commit, dispatch, state }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				purchaseOrders
					.updateStatus(payload)
					.then((response) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							if (payload.status == 4) {
								commit('SET_STATE', {
									filters: {
										status: '3',
									},
								})
								router.push(`/purchase-orders`)
							}
							dispatch('GET', { status: state.filters.status })
							resolve(response)
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Ordenes de compra',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la información.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},

	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		purchaseOrdersList: (state) => state.purchaseOrdersList,
		actualPurchaseOrder: (state) => state.actualPurchaseOrder,
		filters: (state) => state.filters,
		summaryData: (state) => state.summaryData,
		summaryDataFiltered: (state) => state.summaryDataFiltered,
		summaryDataByArea: (state) => state.summaryDataByArea,
		fileURL: (state) => state.fileURL,
		modalFileVisible: (state) => state.modalFileVisible,
		totalPurchaseOrders: (state) => state.totalPurchaseOrders,
		isCashODC: (state) => state.isCashODC,
		fromRLS: (state) => state.fromRLS,
		branch_office_id: (state) => state.branch_office_id,
	},
}
