import Vue from 'vue'
import Vuex from 'vuex'
//
import * as api from '@/services/billing'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import fs from 'fs'

import router from '@/router'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: 'Obteniendo información',
		invoice: '',
		currentStep: 0,
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		CREATE_BILL({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			api
				.createBill(payload)
				.then((response) => {
					Swal.fire({
						title: 'Facturación',
						text: utilities.objectValidate(response, 'data.message', 'Factura generada con éxito.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						router.replace(`/dashboard`)
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Facturación',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al generar la factura.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		DOWNLOAD_BILL({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			api
				.downloadBill(payload)
				.then((response) => {
					let { pdfStream } = response.data

					const file = fs.createWriteStream('./factura.pdf')
					pdfStream.pipe(file)
				})
				.catch((error) => {
					Swal.fire({
						title: 'Facturación',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la factura.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async DOWNLOAD_ZIP({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				api
					.downloadZIP(payload)
					.then((response) => {
						// console.log('downloadZIP-->', response)
						resolve(response)
					})
					.catch((error) => {
						Swal.fire({
							title: 'Facturación',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la factura.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		invoice: (state) => state.invoice,
	},
}
